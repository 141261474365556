@import "./reset.scss";
@import "./variables.scss";

/*******************************************************************************
  global
*******************************************************************************/

*,
:after,
:before {
  box-sizing: border-box;
}

::-moz-selection {
  background: $green-light;
}

::selection {
  background: $green-light;
}

html {
  padding: env(safe-area-inset);
  font-size: 10px;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  background: white;
  color: $default-text;
  line-height: 1.3;
  min-height: 100%;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 1440px;
  display: block;
  margin: 0 auto;
  padding: 0 6rem;

  @media (max-width: $xl) {
    // max-width: 1200px;
    padding: 0 3rem;
  }

  @media (max-width: $lg) {
    padding: 0 1.6rem;
  }

  @media (max-width: $sm) {
    max-width: $xs;
  }
}

.wrap {
  max-width: 53.3rem;

  @media (max-width: $sm) {
    max-width: initial;
  }

  &--center {
    display: flex;
    flex-flow: column nowrap;
    justify-items: flex-start;
    align-items: center;
    text-align: center;
  }
}

img,
svg {
  max-width: 100%;
}

h1 {
  font-size: 4.8rem;
  font-weight: 700;
  color: $black;

  @media (max-width: $lg) {
    font-size: 3.6rem;
  }

  @media (max-width: $sm) {
    font-size: 2.4rem;

    br {
      display: none;
    }
  }

  span {
    color: $green;
  }
}

h2 {
  font-size: 3.6rem;
  font-weight: 700;
  color: $black;

  @media (max-width: $lg) {
    font-size: 3.2rem;
  }

  @media (max-width: $md) {
    font-size: 2.8rem;
  }

  @media (max-width: $xs) {
    font-size: 2.4rem;
  }

  span {
    color: $green;
  }
}

p {
  font-size: 1.8rem;
  line-height: 1.8;

  @media (max-width: $lg) {
    font-size: 1.6rem;
  }

  @media (max-width: $md) {
    font-size: 1.4rem;
  }
}

form {
  display: flex;

  @media (max-width: $xs) {
    flex-direction: column;
    align-items: center;
  }

  &.form-email {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    @media (max-width: $xs) {
      flex-flow: column nowrap;
    }

    .email-input {
      max-width: 36rem;
      width: 100%;
      flex: 1;

      @media (max-width: $xs) {
        margin-bottom: 2rem;
      }
    }
  }

  .email-input {
    position: relative;

    .warning {
      position: absolute;
      font-size: 14px;
      color: $red;
      bottom: 5px;
      left: 0;
      bottom: -20px;
      display: none;

      @media (max-width: $xs) {
        bottom: -17px;
        font-size: 12px;
      }
    }
  }

  &.submitted {
    input[type="email"] {
      &:invalid {
        border-color: $red;
        background-image: url(./img/email_error.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 18px) center;
        background-color: $white;
        z-index: 5;

        @media (max-width: $xs) {
          background-position: calc(100% - 10px) center;
        }

        & + .warning {
          display: block;
        }
      }
    }
  }
}

.form-error-message {
  display: none;
}

input,
textarea {
  width: 100%;
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.05);
  border-radius: 66px;
  height: 6rem;
  padding: 0 4.5rem 0 2.6rem;
  color: $gray;
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  color: $default-text;
  position: relative;

  @media (max-width: $sm) {
    height: 5rem;
    font-size: 1.4rem;
    max-width: 24rem;
  }

  @media (max-width: $xs) {
    max-width: 34.5rem;
    padding-right: 3.5rem;
  }

  &:hover {
    border-color: #cdcdcd;
  }

  &:active,
  &:focus {
    border-color: $green;
  }
}

::placeholder {
  color: $gray;
  font: "Poppins", sans-serif;
}

button {
  padding: 0;
  width: 100%;
  height: 60px;
  max-width: 193px;
  background: $green;
  color: $white;
  box-shadow: 0px 6px 15px rgba(0, 162, 76, 0.2);
  border-radius: 30px;
  border: none;
  padding: 0 2rem;
  margin-left: 3rem;
  font-weight: 700;
  font-size: 1.8rem;
  position: relative;

  @media (max-width: $sm) {
    max-width: 16rem;
    font-size: 1.4rem;
    height: 5rem;
  }

  @media (max-width: $xs) {
    margin-left: 0rem;
  }
}

.availability {
  max-width: 50rem;
}

.link-group {
  margin: 3rem 0;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .link--store:not(:last-child) {
    margin-right: 1rem;
  }
}

.logo {
  height: 30px;
}

.form-btn {
  padding: 15px 30px;
  width: auto;
  background: transparent;
  color: $green;
  // box-shadow: none;
  // white-space: pre;

  span {
    margin-left: 13px;

    @media (max-width: $sm) {
      margin-left: 11px;
    }
  }

  &--success {
    background: $green;
    color: $white;
    border-radius: 30px;
    font-weight: 700;
  }

  &__before {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    width: 59px;
    max-width: 193px;
    padding-left: 20px;
    height: 60px;
    display: flex;
    align-items: center;
    box-shadow: 0px 6px 15px rgba(0, 162, 76, 0.2);
    transition: 0.5s;
    overflow-x: hidden;
    font-weight: 700;
    font-size: 1.8rem;

    @media (max-width: $sm) {
      width: 5rem;
      height: 5rem;
      max-width: 16rem;
      font-size: 1.4rem;
    }
  }

  &:hover {
    .form-btn__before {
      width: 100%;
    }
  }
}

.remark {
  position: relative;
  margin-bottom: 1rem;

  .circle {
    background-color: $green-light;
    width: 15rem;
    height: 15rem;
    position: absolute;
    left: -6.8rem;
    bottom: -1.8rem;
    z-index: 1;
    border-radius: 100%;

    @media (max-width: $sm) {
      width: 9.4rem;
      height: 9.4rem;
      left: -2.8rem;
      bottom: -0.8rem;
    }
  }

  span {
    position: relative;
    z-index: 2;
    font-weight: 500;
    line-height: 1.5;
    color: $green;
  }
}

.media {
  video {
    width: 100%;
    max-width: 53rem;

    @media (max-width: $xl) {
      max-width: 47rem;
    }

    @media (max-width: $lg) {
      max-width: 39rem;
    }

    @media (max-width: $md) {
      max-width: 29rem;
    }

    @media (max-width: $sm) {
      max-width: 33rem;
    }
  }
}

/*******************************************************************************
 home page
*******************************************************************************/

// @import './styles for home.scss';
@import "./header.scss";
@import "./application.scss";
@import "./security.scss";
@import "./profiles.scss";
@import "./multiple.scss";
@import "./footer.scss";
@import "./modal-email.scss";
@import "./modal-contact.scss";
@import "./animation.scss";
