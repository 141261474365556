header {
  background: $white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;

  .container {
    height: 76px;
    display: flex;
    align-items: center;

    @media screen and (min-width: $md) {
      padding: 0;
    }
  }

  .logo {
    margin-left: 3rem;

    @media (max-width: $md) {
      margin-left: 1.6rem;
    }

    @media (max-width: $sm) {
      margin-left: 0;
    }
  }

  .nav {
    display: flex;
    margin-left: auto;

    @media (max-width: $sm) {
      display: none;
    }

    &__link {
      padding: 1rem 2.5rem;
      font-weight: 500;
      cursor: pointer;
      white-space: pre;
      color: $black;
      text-decoration: none;

      @media (max-width: $md) {
        font-size: 1.4rem;
        padding: 1rem 1.5rem;
      }
    }
  }

  .blog-link {
    @media (max-width: $sm) {
      margin-left: auto;
    }
  }

  .contact-btn {
    flex-shrink: 1;
    padding-right: 25px;
    padding-left: 25px;
    height: 46px;
    color: $green;
    background: $green-light;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 63px;
    cursor: pointer;
    white-space: pre;

    margin-right: 30px;
    margin-left: 35px;

    @media (max-width: $md) {
      font-size: 1.4rem;
      margin-left: 1.5rem;
      margin-right: 1.6rem;
    }

    @media (max-width: $sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
