
section.security {
  padding-top: 19.6rem;
  padding-bottom: 7rem;
    
  @media (max-width: $xl) {
    padding-top: 14rem;
  }

  @media (max-width: $md) {
    padding-top: 12rem;
    padding-bottom: 3rem;
  }

  @media (max-width: $sm) {
    padding-top: 5rem;
  }

  .container {
    display: flex;
    justify-content: space-between;
    position: relative;

    @media (max-width: $sm) {
      display: block;
    }
  }

  .wrap {
    flex: 5 0 0;
  }

  h2 {
    @media (max-width: $sm) {
      text-align: center;
      padding-bottom: 26rem;
    }
  }

  ul {

    @media (max-width: $sm) {
      max-width: 375px;
      margin-left: auto;
      margin-right: auto;
    }

    li {
      font-size: 1.8rem;
      line-height: 1.8;
      padding-left: 11.2rem;
      margin-top: 4rem;
      min-height: 7rem;
      position: relative;

      @media (max-width: $lg) {
        padding-left: 8.3rem;
        font-size: 1.6rem;
      }

      @media (max-width: $md) {
        margin-top: 3rem;
        font-size: 1.4rem;
      }

      @media (max-width: $sm) {
        padding-left: 9rem;
      }

      .li_icon {
        position: absolute;
        left: 0;
        top: 0.5rem;
      }
    }
  }

  .illustration{
    position: relative;
    flex: 7 0 0;
    justify-content: flex-end;
    display: flex;

    &__quote {
      position: relative;
      max-width: 85%;
      display: inline-block;

      @media (max-width: $md) {
        margin-top: 9.5rem;
      }

      @media (max-width: $sm) {
        margin-top: 0;
        width: 245px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    @media (max-width: $sm) {
      margin-top: 0;
      position: absolute;
      top: 11rem;
      left: 0;
      right: 0;
    }
  }

  .image {
    &__quote {
      display: block;
      position: relative;
      z-index: 2;

      &-man {
        position: absolute;
        z-index: 3;
        top: 0;
        right: 0;
        left: 0;
        bottom: 25%;
        margin: auto;
      }
    }

    &__bird {
      position: absolute;
      left: 0rem;
      top: -8rem;
      z-index: 3;

      @media (max-width: $md) {
        display: none;
      }
    }
  }
}
