.modal {
  display: none;
  animation: 2s;

  &.is-open {
    display: block;
    animation: 2s;
  }

  .close {
    background: url(./img/modal_close.svg);
    cursor: pointer;
    width: 2.4rem;
    height: 2.4rem;
  }

  &[aria-hidden="false"] {
    .overlay {
      animation: mmfadeIn 0.5s;
    }

    .email__wrap {
      animation: mmslideIn 0.5s;
    }

    .contact__content {
      animation: mmslideIn 0.5s;
    }
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(-15%);
  }
  to {
    transform: translateY(0);
  }
}

.contact {
  &__overlay {
    will-change: transform;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    overflow-y: auto;
    overflow-x: hidden;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);

    @media (orientation: landscape) and (max-width: $md) {
      align-items: flex-start;
    }
  }

  &__content {
    padding: 3rem 4rem;
    position: relative;
    border: 1px solid #888;
    background: #f7f7f7;
    border-radius: 8px;
    max-width: 546px;
    width: 90%;
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);

    @media (max-width: $sm) {
      padding: 2rem;
    }
  }

  &__close {
    position: absolute;
    right: 3.2rem;
    top: 3.2rem;

    @media (max-width: $sm) {
      right: 2rem;
      top: 2rem;
    }
  }

  h4 {
    margin-bottom: 3rem;
    color: $black;
    font-size: 2.4rem;
    font-weight: 600;

    @media (max-width: $xs) {
      font-size: 1.8rem;
    }
  }

  form {
    display: block;
  }

  .contact__input {
    position: relative;
    margin-bottom: 3rem;

    @media (max-width: $xs) {
      margin-bottom: 2rem;
    }

    svg {
      position: absolute;
      right: 2.4rem;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;

      @media (max-width: $xs) {
        right: 1.4rem;
      }
    }
  }

  input {
    max-width: 100%;
    border: 1px solid #ededed;
    margin-bottom: 0;
    padding-right: 4.5rem;

    @media (max-width: $xs) {
      padding-right: 3.5rem;
      padding-left: 1.6rem;
    }

    &:hover {
      border-color: #cdcdcd;
    }

    &:active,
    &:focus {
      border-color: $green;
    }
  }

  textarea {
    border: 1px solid #ededed;
    max-width: 100%;
    height: 11.4rem;
    border-radius: 16px;
    padding: 1.5rem 2.2rem;
    margin-bottom: 3rem;

    @media (max-width: $xs) {
      margin-bottom: 2rem;
      padding: 1rem 1.6rem;
    }

    &:hover {
      border-color: #cdcdcd;
    }

    &:active,
    &:focus {
      border-color: $green;
    }
  }

  button {
    margin-left: auto;
    max-width: 106px;
    display: block;
  }
}

.success-message {
  img {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 3rem;
  }

  h4 {
    text-align: center;
  }
}
