.email {
  &__overlay {
    animation: 2s;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
    overflow: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $default-bg;
  }

  &__wrap {
    animation: 2s;
    max-width: 64.5rem;
    text-align: center;
    position: relative;
    z-index: 92;

    @media (max-width: $sm) {
      max-width: 43rem;
      padding: 0 2rem;
    }

    p {
      line-height: 1.7;
    }
  }

  &__success {
  }

  &__title {
    font-size: 3rem;
    line-height: 1.5;
    color: $black;
    font-weight: 700;
    margin: 2rem 0;

    @media (max-width: $sm) {
      font-size: 2.4rem;
    }

    @media (max-width: $xs) {
      margin: 1rem 0;
    }
  }

  &__counter {
    margin: 3rem 0;
    font-weight: 600;
    font-size: 2.4rem;
    color: $black;

    @media (max-width: $sm) {
      font-size: 2rem;
    }

    @media (max-width: $xs) {
      font-size: 1.5rem;
      margin-top: 1.5rem;
    }

    span {
      color: $green;
    }
  }

  .social {
    position: absolute;
    bottom: 1.5rem;
    left: 3rem;
    display: flex;
    align-items: center;

    &__links {
      display: flex;
    }

    a {
      padding: 1.5rem 1rem;
      display: block;

      @media (max-width: $xs) {
        padding: 0.5rem 1rem;
      }
    }

    span {
      font-weight: 500;
      padding-right: 1rem;

      @media (max-width: $xs) {
        padding-left: 1rem;
      }
    }
  }

  &__letter {
    position: absolute;
    left: 0;
    top: 4.2rem;

    @media (max-width: $md) {
      max-width: 25rem;
    }

    @media (max-width: $sm) {
      display: none;
    }
  }

  &__boy {
    position: absolute;
    z-index: 91;
    right: 3rem;
    bottom: 0;

    @media (max-width: 1600px) {
      max-width: 35rem;
    }

    @media (max-width: $md) {
      max-width: 15rem;
    }

    @media (max-width: $sm) {
      display: none;
    }
  }

  &__close {
    position: absolute;
    right: 3rem;
    top: 3rem;
    z-index: 91;

    @media (max-width: $sm) {
      right: 2rem;
      top: 2rem;
    }
  }
}
