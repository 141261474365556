footer {
  background: $default-bg;

  .container {
    position: relative;
  }

  .logo {
    position: absolute;
    top: 2rem;
    left: 3rem;
  }

  .social {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    display: flex;
    align-items: center;

    @media (max-width: $xs) {
      display: block;
      top: 1rem;
      right: 0.5rem;
    }

    &__links {
      display: flex;
    }

    a {
      padding: 1.5rem 1rem;
      display: block;

      @media (max-width: $xs) {
        padding: 0.5rem 1rem;
      }
    }

    span {
      font-weight: 500;
      padding-right: 1rem;

      @media (max-width: $xs) {
        width: 100%;
        padding-left: 1rem;
      }
    }
  }

  h3 {
    text-align: center;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 1.5;
    padding-top: 3rem;
    color: $black;

    @media (max-width: $md) {
      padding-top: 7.2rem;
      font-size: 1.6rem;
    }

    @media (max-width: $sm) {
      padding-top: 10rem;
    }
  }

  form {
    max-width: 59rem;
    margin-top: 3rem;
    margin-bottom: 2.6rem;

    @media (max-width: $md) {
      margin-top: 3rem;
      margin-bottom: 8.3rem;
      max-width: 51rem;
    }

    @media (max-width: $sm) {
      margin-bottom: 7rem;
      max-width: 42rem;
    }

    @media (max-width: $xs) {
      margin-bottom: 2rem;
    }
  }

  .copyright {
    text-align: center;
    padding-bottom: 1rem;
    color: $gray;
    font-size: 1.4rem;
    line-height: 2.1rem;

    @media (max-width: $xs) {
      padding-bottom: 0;
    }
  }
}
