section.aplication {
  padding-top: 76px;
  background-image: linear-gradient(-110deg, $white 50%, $default-bg 50%);
  background-repeat: no-repeat;
  overflow: hidden;

  .container {
    position: relative;
  }

  .wrap {
    margin-right: auto;
    margin-left: auto;
    padding-top: 8rem;
    padding-bottom: 21.2rem;

    @media (max-width: $md) {
      max-width: 40rem;
      padding-bottom: 24.2rem;
    }

    @media (max-width: $xs) {
      margin-left: auto;
      margin-right: auto;
      max-width: 35rem;
      padding-bottom: 20rem;
    }
  }

  .description {
    margin-top: 1.5rem;
  }

  .availability {
    margin-bottom: 1.5rem;
  }

  p {
    line-height: 1.7;

    @media (max-width: $lg) {
      max-width: 46rem;
    }

    @media (max-width: $md) {
      font-size: 1.6rem;
    }

    @media (max-width: $sm) {
      font-size: 1.4rem;
      max-width: 35rem;
    }
  }

  // form {
  //   margin-right: -6rem;

  //   @media (max-width: $md) {
  //     margin-right: -11rem;
  //   }

  //   @media (max-width: $sm) {
  //     margin-right: 0rem;
  //   }
  // }

  .image {
    position: absolute;

    &__plane {
      top: 14rem;
      position: absolute;
      right: 54%;
      transform: translate(-50%, 0);

      @media (max-width: $md) {
        right: 50%;
      }

      @media (max-width: $sm) {
        top: 2rem;
        right: 25%;
      }

      @media (max-width: $xs) {
        display: none;
      }

      .plane {
        opacity: 0;
        animation: 3s ease 2.3s normal forwards 1 planeIn;
      }

      @keyframes planeIn {
        to {
          opacity: 1;
        }
      }

      svg {
        max-width: initial;
      }
    }

    &__table {
      left: 6rem;
      bottom: 3.8rem;

      @media (max-width: $lg) {
        max-width: 17rem;
        left: 1.6rem;
      }

      @media (max-width: $md) {
        bottom: 3rem;
      }

      @media (max-width: $xs) {
        left: -3rem;
        bottom: 1.6rem;
      }
    }

    &__line {
      left: 88%;
      top: 7rem;

      @media (max-width: $lg) {
        left: 90%;
        top: 10.8rem;
      }

      @media (max-width: $sm) {
        left: 89%;
      }

      @media (max-width: $xs) {
        display: none;
      }

      svg {
        max-width: initial;
      }
    }

    &__cycle {
      right: 6rem;
      bottom: 4.3rem;
      animation: fadein 3s;

      @media (max-width: $lg) {
        max-width: 17rem;
        right: 1.6rem;
      }

      @media (max-width: $md) {
        bottom: 3rem;
      }

      @media (max-width: $xs) {
        right: -3.5rem;
        bottom: 2rem;
      }
      .wheel {
        transform: rotate(0);
        transform-origin: 80% 78% 0;
        transition: transform 1.5s;
        animation: rotate-me2 2s linear alternate forwards;
      }

      .wheel-2 {
        transform: rotate(0);
        transform-origin: 20% 78% 0;
        transition: transform 1.5s;
        animation: rotate-me2 2s linear alternate forwards;
      }

      @keyframes rotate-me2 {
        100% {
          transform: rotate(-360deg);
        }
      }
    }

    @keyframes fadein {
      from {
        right: -50%;
      }
    }
  }

  .path-1 {
    stroke-dasharray: 1000;
    animation: dash 3s linear alternate-reverse forwards;
  }

  .path-2 {
    stroke-dasharray: 750;
    animation: dash-2 2.5s linear alternate forwards;

    @media (max-width: $xl) {
      stroke-dasharray: 620;
    }
  }

  @keyframes dash {
    from {
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 2000;
    }
  }

  @keyframes dash-2 {
    from {
      stroke-dashoffset: 0;
    }
    to {
      stroke-dashoffset: 750;

      @media (max-width: $xl) {
        stroke-dashoffset: 620;
      }
    }
  }
}
