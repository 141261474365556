
section.multiple {
  padding-bottom: 18rem;

  @media (max-width: $md) {
    padding-bottom: 10rem;
  }

  @media (max-width: $xs) {
    padding-bottom: 4rem;
  }

  .column {
    flex: 1 0 0;

    &-wrap {
      display: flex;
      flex-flow: row;

      @media (max-width: $sm) { 
        flex-direction: column-reverse;
      }
    }
  }

  .wrap {
    padding-top: 6rem;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: $xl) {
      padding-left: 4rem;
    }

    @media (max-width: $lg) {
      max-width: 40rem;
      padding-left: 0;
      margin-right: 0;
    }

    @media (max-width: $md) {
      max-width: 28rem;
    }

    @media (max-width: $sm) { 
      max-width: 35rem;
      margin-right: auto;
    }

    @media (max-width: $xs) { 
      text-align: center;
    }

    p {
      width: 95%;
    }
  }

  .remark {
    .circle {
      bottom: -0.3rem;

      @media (max-width: $md) {
        width: 7.5rem;
        height: 7.5rem;
        left: -2.8rem;
      }
    }
  }

  h2 {
    line-height: 1.5;
    position: relative;
    z-index: 2;
    padding-bottom: 1.5rem;
  }

  .media {
    max-width: 55rem;
    margin-right: auto;
    margin-left: auto;
    position: relative;

    @media (max-width: $lg) {
      max-width: 48rem;
    }

    @media (max-width: $md) {
      max-width: 30rem;
    }
  }

  video {
    position: relative;
    z-index: 1;
  }

  .quote {
    position: absolute;
    z-index: 2;

    &__right {
      right: -3rem;
      top: -10rem;

      @media (max-width: $xl) {
        right: 2rem;
      }

      @media (max-width: $lg) {
        max-width: 24rem;
        top: -7.8rem;
        right: 5rem;
      }

      @media (max-width: $md) {
        max-width: 18rem;
        top: -5.8rem;
        right: -2rem;
      }

      @media (max-width: $sm) {
        right: -1.3rem;
        max-width: 15rem;
        top: -4.8rem;
      }
    }

    &__left {
      left: -2.1rem;
      top: 30rem;

      @media (max-width: $xl) {
        left: -3.1rem;
      }

      @media (max-width: $lg) {
        max-width: 17rem;
        left: -2.8rem;
        top: 22rem;
      }
      
      @media (max-width: $md) {
        max-width: 11.5rem;
        left: -1rem;
      }
    }
  }

  .video-wrap {
    position: relative;
    display: inline-block;
    margin-top: -25rem;
    left: 25%;
    transform: translate(-50%, 0);

    @media (max-width: $xl) {
      max-width: 50rem;
    }

    @media (max-width: $lg) {
      margin-top: -5rem;
      left: 35%;
    }

    @media (max-width: $md) {
      margin-top: 5rem;
      left: 50%;
    }

    @media (max-width: $xs) {
      max-width: 34.5rem;
    }

    video {
      width: 100%;
      max-width: 66rem;
    }

    .mess-hi {
      position: absolute;
      z-index: 3;
      top: 0;
      bottom: 40%;
      margin: auto 0;

      @media (max-width: $xs) {
        max-width: 17rem;
      }
    }

    .mess-hey {
      position: absolute;
      right: -2rem;
      top: 0;
      bottom: 10%;
      margin: auto 0;
      z-index: 3;

      @media (max-width: $xs) {
        right: 0;
        max-width: 20rem;
        top: 30%;
        bottom: 0;
      }
    }
  }
}