
section.profiles {
    padding-top: 10rem;
    padding-bottom: 17rem;
    overflow: hidden;

    @media (max-width: $md) {
      padding-bottom: 13rem;
    }

    @media (max-width: $sm) { 
      padding-top: 5rem;
    }

    @media (max-width: $xs) { 
      padding-top: 11rem;
    }
  
    .container {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: $sm) { 
        display: block;
      }
    }
  
  
    .media {
      width: 40%;
  
      @media (max-width: $sm) { 
        width: 100%;
      }

      video {
        margin-right: auto;
        margin-left: auto;
        display: block;
      }
    }
  
    .column {
      width: 60%;

      @media (max-width: $sm) { 
        width: 100%;
      }

      &__letter {
        padding-top: 16rem;
        max-width: 100%;

        @media (max-width: $lg) {
          padding-top: 13.6rem;
        }

        @media (max-width: $md) {
          padding-top: 10rem;
        }

        @media (max-width: $sm) {
          display: none;
        }

        img {
          padding-left: 33.5%;
          max-width: initial;
          display: block;
        }
      }
    }
  
    .wrap {
      padding-top: 19rem;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: $lg) {
        padding-top: 11rem;
        max-width: 38rem;
      }

      @media (max-width: $md) {
        padding-top: 2rem;
        max-width: 27.8rem;
      }

      @media (max-width: $sm) {
        padding-top: 6rem;
        max-width: 35rem;
      }

      @media (max-width: $xs) {
        text-align: center;
      }
    }
  
    h2 {
      line-height: 1.5;
      position: relative;
      z-index: 2;
      padding-bottom: 1.5rem;
    }
  
    .remark {
      .circle-small{
        background-color: $default-bg;
        width: 7.5rem;
        height: 7.5rem;
        position: absolute;
        right: 3rem;
        bottom: -2.8rem;
        z-index: 1;
        border-radius: 100% ;

        @media (max-width: $sm) {
          width: 5.6rem;
          height: 5.6rem;
          right: -5rem;
        }

        @media (max-width: $xs) {
          right: 0;
        }
      }
    }
  }