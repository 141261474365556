$default-text: #505050;
$default-bg: #FAF8F2;

$white: #fff;
$black: #000;
$gray: #CFCFCF;
$green: #00A24C;
$green-light:#E5FFE6;
$red: #FF4F00;



// media break-points

$xl: 1440px;
$lg: 1199.98px;
$md: 991.98px;
$sm: 767.98px;
$xs: 575.98px;